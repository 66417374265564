.Navbar {
  position: fixed;
  top: 0;
  background-color: #fff;
  width: 16rem;
  height: 100%;
  z-index: 999;
  &_logo {
    padding: 10px;
  }

  &_help {
    padding: 0px 0px 0px 30px;
    font-size: 18px;
    font-weight: bold;
    color: blue;
    &_seting {
      padding: 10px 0px 10px 10px;
      cursor: pointer;
      &:hover {
        background-color: rgb(194, 194, 245);
      }
    }
  }

  &_link {
    display: flex;
    align-items: flex-end;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    color: blue;
    cursor: pointer;
    &:hover {
      background-color: rgb(194, 194, 245);
    }
  }
  &_menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 135px);
    nav {
      height: calc(100vh - 70px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    ul {
      padding-left: 0;
      li {
        list-style-type: none;
        a {
          text-decoration: none;
        }
      }
    } 
  }
  &_btn {
    padding: 10px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    &_campaign {
      background-color: #4bacff;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: #2b7dff;
      }
    }
    &_file {
      background-color: #80c433;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: #9eeb47;
      }
    }
    &_helpFb {
      background-color: #8651EF;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        background-color: #972efa;
      }
    }
  }
}