.VerifyPhone {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    &_box {
        padding: 20px;
        border-radius: 10px;
        background: white;

        &_title {
            text-align: center;
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: bold;
        }

        &_form {
            input {
                outline: none;
                border: 1px solid;
                border-radius: 5px;
                height: 30px;
                width: 180px;
                padding: 5px 10px;
                margin-right: 20px;
                margin-bottom: 5px;
            }

            button {
                cursor: pointer;
                height: 40px;
                border-radius: 5px;
                border: 1px solid green;
                background: green;
                color: white;
                font-weight: bold;
            }

            button:hover {
                background: #458002;
            }

            &_acction {
                display: flex;
                font-size: 12px;
                font-weight: bold;
                color: white;

                &_resend {
                    cursor: pointer;
                    padding: 5px;
                    background: brown;
                    border-radius: 5px;
                    margin-right: 5px;

                    &:hover {
                        background: #a50000;
                    }
                }

                &_change {
                    cursor: pointer;
                    padding: 5px;
                    background: burlywood;
                    border-radius: 5px;
                    margin-right: 5px;

                    &:hover {
                        background: rgb(204, 155, 90);
                    }
                }

                &_countdown {
                    font-size: 12px;
                    font-weight: bold;
                    color: red;
                }

            }
        }
    }
}