.DetailPackage {
    position: relative;
    &_bg {
        background-color: #4bacff;
        padding-bottom: 9.5rem;
        padding-top: 4rem;
    }
    &_content {
        padding-right: 2rem;
        padding-left: 2rem;
        margin-top: -10rem;
        h3, h4 {
            margin: 0px;
        }
        &_header {
            &_title {
                width: 100%;
                color: #ffff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                >h3 {
                    font-size: calc(1.275rem + .3vw);
                }
            }
        }
        &_body {
            margin-top: 2rem;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            &_oder {
                background-color: #ffff;
                padding: 20px;
                border-radius: 10px;
                margin-right: 10px;
                margin-top: 10px;
                height: 150px;


                &_title {
                    font-weight: bold;
                    font-size: 25px;
                    margin: 5px 0px;
                }

                &_item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 20px;

                    &_title {
                        font-weight: bold;
                        width: 200px;
                    }
                }
            }

            &_checkout {
                background-color: #ffff;
                padding: 20px;
                border-radius: 10px;
                margin-left: 10px;
                margin-top: 10px;

                &_title {
                    font-weight: bold;
                    font-size: 25px;
                    margin: 5px 0px;
                }

                &_item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 20px;

                    &_title {
                        font-weight: bold;
                        width: 200px;
                    }
                }

                &_total {
                    border-top: 1px solid #ccc;
                    padding-top: 10px;
                    text-align: right;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    font-size: 20px;
                }

                &_methods {
                    border-top: 1px solid #ccc;
                    padding: 10px 0px;
                    &_title {
                        font-weight: bold;
                        color: green;
                    }

                    &_chose {
                        padding: 10px;
                        &_checkbox {
                            display: block;
                            position: relative;
                            padding-left: 35px;
                            margin-bottom: 20px;
                            cursor: pointer;
                            font-size: 14px;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            input {
                                position: absolute;
                                opacity: 0;
                                cursor: pointer;
                                height: 0;
                                width: 0;
                            }
                            .checkmark {
                                position: absolute;
                                top: -4px;
                                left: 0;
                                height: 25px;
                                width: 25px;
                                background-color: #eee;
                            }
                            &:hover input ~ .checkmark {
                                background-color: #ccc;
                            }
                            input:checked ~ .checkmark {
                                background-color: #2196F3;
                            }
                            .checkmark:after {
                                content: "";
                                position: absolute;
                                display: none;
                            }
                            input:checked ~ .checkmark:after {
                                display: block;
                            }
                            .checkmark:after {
                                left: 9px;
                                top: 5px;
                                width: 5px;
                                height: 10px;
                                border: solid white;
                                border-width: 0 3px 3px 0;
                                -webkit-transform: rotate(45deg);
                                -ms-transform: rotate(45deg);
                                transform: rotate(45deg);
                            }
                        }
                    }
                }
                
                &_btn {
                    padding: 20px;
                    text-align: center;
                    background: green;
                    color: white;
                    border-radius: 10px;
                    font-weight: bold;
                    cursor: pointer;
                    &:hover {
                        background: #458002;
                    }
                }
            }
        }
    }
    .ModalTransfer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100vh - 70px);
        background-color: #00000033;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        &_content {
            background-color: #ffff;
            padding: 20px;
            border-radius: 10px;
            &_title {
                font-weight: bold;
                font-size: 25px;
                margin: 5px 0px;
                color: #458002;
            }
            &_body {
                &_title {
                    font-weight: bold;
                    font-size: 20px;
                    margin: 5px 0px;
                    color: red;
                }

                &_content {
                    display: flex;
                    align-items: center;

                    &_qr {
                        img {
                            width: 300px;
                        }
                    }

                }
                &_close {
                    display: flex;
                    justify-content: center;
                    &_btn {
                        width: 100px;
                        padding: 10px;
                        border-radius: 10px;
                        background: red;
                        color: white;
                        font-weight: bold;
                        margin-top: 10px;
                        cursor: pointer;
                        text-align: center;
                        &:hover {
                            background: #458002;
                        }
                    }
                }
            }
        }
    }
}