.modalSetting {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    &_container {
        background: white;
        padding: 25px;
        border-radius: 20px;
    }
    &_close {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        &_btn {
            width: 100px;
            height: 40px;
            border-radius: 5px;
            background: green;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            font-weight: bold;
            color: white;
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
        }
    }
}