.Home {
  display: flex;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
  &_Header {
    --header-prime-color: 128 135 147;
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding-left: 16rem;
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    display: flex;
    height: 70px;
    border-bottom: 1px solid #ccc;
    box-shadow: 0 0 2rem 0 rgba(136,152,170,.15);
    z-index: 99;
  }
  &_Content {
    margin-left: 16rem;
    margin-top: 70px;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    background: #f1f5f9;
    min-height: calc(100vh - 70px)
  }
}