.Information {
    width: 100%;
    &_bg {
        background-color: #4bacff;
        padding-bottom: 9.5rem;
        padding-top: 4rem;
    }
    &_upgrade {
        cursor: pointer;
        background: #FFA500;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        width: 135px;
        height: 40px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            background: #f8ad21;
        }
    }
    &_content {
        padding-right: 2rem;
        padding-left: 2rem;
        margin-top: -10rem;
        h3, h4 {
            margin: 0px;
        }
        &_header {
            &_title {
                width: 100%;
                color: #ffff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                >h3 {
                    font-size: calc(1.275rem + .3vw);
                }
            }
            &_flex {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
            &_card {
                margin-top: 2rem;
                width: 49%;
                &_item {
                    box-shadow: 0 2px 4px rgba(0,0,20,.08), 0 1px 2px rgba(0,0,20,.08);
                    border: 0;
                    border-radius: 0.5rem;
                    position: relative;
                    display: flex;
                    flex-shrink: 0;
                    flex-direction: column;
                    min-width: 0;
                    word-wrap: break-word;
                    background-color: #fff;
                    background-clip: border-box;
                    &_body {
                        padding: 15px 20px;
                        &_row {
                            display: flex;
                            margin-bottom: 0.5rem;
                            align-items: center;
                            justify-content: space-between;
                        }
                    }
                }
            }
        }
    }
    &_page {
        margin-top: 2rem;
        &_card {
            flex: 0 0 auto;
            width: 100%;
            &_body {
                box-shadow: 0 2px 4px rgba(0,0,20,.08), 0 1px 2px rgba(0,0,20,.08);
                border: 0;
                border-radius: 0.5rem;
                &_header {
                    border-radius: 0.5rem 0.5rem 0;
                    padding: 1.25rem 0 0 0;
                    background-color: #fff;
                    >h4 {
                        padding: 0 1.25rem 0.5rem 1.25rem;
                        margin: 0;
                        font-size: 1.125rem;
                        font-weight: 500;
                        line-height: 1.2;
                        color: #212b36;
                        border-bottom: 1px solid rgb(0 0 0 / 18%);
                    }
                }
                &_table {
                    overflow-x: auto;
                    tbody,td,tfoot,th,thead,tr {
                        border: 0 solid;
                        border-color: inherit;
                    }
                    &_tablePage {
                        width: 100%;
                        color: #637381;
                        vertical-align: top;
                        border-color: #dfe3e8;
                        border-collapse: collapse;
                        >:not(caption)>*>* {
                            padding: 0.75rem 1.5rem;
                            background-color: transparent;
                            border-bottom-width: 1px;
                            box-shadow: inset 0 0 0 9999px transparent;
                        }
                        &_thead {
                            vertical-align: bottom;
                            color: #000;
                            border-color:#dfe3e8;
                            border: 0 solid;
                        }
                        &_tr {
                            cursor: pointer;
                            background: #fff;
                            &_tdName {
                                display: flex;
                                align-items: center;
                                img {
                                    border-radius: 50%;
                                    width: 40px;
                                    margin-right: 10px;
                                }
                            }
                            &:hover {
                                background-color: rgb(174, 174, 248);
                            }
                        }
                        thead th {
                            font-weight: 500;
                            padding: 0.75rem 1.5rem;
                            text-transform: capitalize;
                            font-size: .875rem;
                            color: #637381;
                            text-align: left;
                        }
                    }
                }
                &_footer {
                    background-color: #fff;
                    border-radius: 0 0 0.5rem 0.5rem;
                    text-align: center;
                    padding: 1.25rem;
                }
            }
        }
    }

    &_changePage {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999999999;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        &_box {
            width: 70%;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;

            &_title {
                font-size: 20px;
                color: #4bacff;
                font-weight: bold;
            }

            &_action {
                margin-top: 10px;
                display: flex;
                gap: 5px;

                &_btn1{
                    padding: 10px;
                    background: green;
                    color: white;
                    border-radius: 5px;
                    cursor: pointer;
                    text-decoration: none;

                    &:hover {
                        background: rgb(1, 167, 1);
                    }
                }

                &_btn2{
                    padding: 10px;
                    background: orange;
                    color: white;
                    border-radius: 5px;
                    cursor: pointer;
                    text-decoration: none;

                    &:hover {
                        background: rgb(247, 166, 15);
                    }
                }
            }
        }
    }
}