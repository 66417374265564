.usersAdmin {
  width: 100%;
  padding: 1rem 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_content {
    display: flex;
    align-items: center;
    position: relative;
  }
  &_imformation {
    cursor: pointer;
    text-align: center;
    padding: 0px 8px;
    &_img {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &_avatar {
      border-radius: 50%;
    }
    &:hover {
      .usersAdmin_imformation_acount {
        display: block;
      }
    }
    &_acount {
      display: none;
      position: absolute;
      background: #f1f5f9;
      border-radius: 6px;
      padding: 6px;
      top: 62px;
      right: 0;
      font-size: 12px;
      width: max-content;  
    }
    &_btn {
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 20px;
      height: 20px;
      text-align: center;
      background-color: #4B4C4F;
      border-radius: 50%;
    }
  }
  &_menu {
    width: 172px;
    position: absolute;
    border-radius: 5px;
    padding: 10px 0px;
    background-color: #fff;
    border: 1px solid #ccc;
    top: 55px;
    right: 0px;
    z-index: 99;
    box-shadow: 0 0 2rem 0 rgba(136,152,170,.15);
    &_item {
      cursor: pointer;
      display: flex;
      padding: 5px 10px;
      align-items: center;
      &:hover {
        background-color: cornflowerblue;
      }
      &_line {
        border-bottom: 1px solid #ccc;
        margin: 10px;
      }
      &_name {
        margin-left: 5px;
        font-weight: bold;
      }
      &_avatar {
        border-radius: 50%;
      }
    }
    &_text {
      margin-left: 5px;
    }
  }
}