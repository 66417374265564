.BettingPackage {
    width: 100%;
    &_bg {
        background-color: #4bacff;
        padding-bottom: 9.5rem;
        padding-top: 4rem;
    }

    &_content {
        padding-right: 2rem;
        padding-left: 2rem;
        margin-top: -10rem;
        h3, h4 {
            margin: 0px;
        }
        &_header {
            &_title {
                width: 100%;
                color: #ffff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                >h3 {
                    font-size: calc(1.275rem + .3vw);
                }
            }
        }

        &_body {
            margin-top: 2rem;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            &_item {
                position: relative;
                width: 300px;
                height: 480px;
                background-color: #ffff;
                border-radius: 10px;
                margin: 10px 15px;
                box-shadow: 0 2px 4px rgba(0, 0, 20, 0.08), 0 1px 2px rgba(0, 0, 20, 0.08);

                &:hover {
                    transform: translateY(-5px);
                    box-shadow: 
                        0 2px 4px rgba(0, 0, 20, 0.08),
                        0 1px 2px rgba(0, 0, 20, 0.08),
                        0 2px 4px rgba(0, 0, 20, 0.08),
                        0 1px 2px rgba(0, 0, 20, 0.08);
                }

                &_title {
                    font-size: 30px;
                    font-weight: bold;
                    text-align: center;
                    padding: 20px;
                }

                
                /* Định nghĩa animation rainbow */
                @keyframes rainbow {
                    0% {
                        background-image: linear-gradient(to right, rgba(14, 49, 247, 0.8), rgba(245, 8, 205, 0.8));
                    }
                    14.28% {
                        background-image: linear-gradient(to right, rgba(245, 8, 205, 0.8), rgba(248, 6, 228, 0.8));
                    }
                    28.56% {
                        background-image: linear-gradient(to right, rgba(248, 6, 228, 0.8), rgba(247, 134, 6, 0.911));
                    }
                    42.84% {
                        background-image: linear-gradient(to right, rgba(247, 134, 6, 0.911), rgba(248, 123, 6, 0.8));
                    }
                    57.12% {
                        background-image: linear-gradient(to right, rgba(248, 123, 6, 0.8), rgba(6, 110, 247, 0.911));
                    }
                    71.4% {
                        background-image: linear-gradient(to right, rgba(6, 110, 247, 0.911), rgba(14, 49, 247, 0.8));
                    }
                    85.68% {
                        background-image: linear-gradient(to right, rgba(14, 49, 247, 0.8), rgba(245, 8, 205, 0.8));
                    }
                    100% {
                        background-image: linear-gradient(to right, rgba(245, 8, 205, 0.8), rgba(14, 49, 247, 0.8));
                    }
                }

                &_price_1 {
                    font-size: 40px;
                    font-weight: bold;
                    color: #ffff;
                    text-align: center;
                    background-image: linear-gradient(to right, rgba(14, 49, 247, 0.8), rgba(245, 8, 205, 0.8));
                    transition: background-color 0.5s ease; /* Thêm transition để tạo hiệu ứng mượt mà */
                }
                
                /* Thêm hiệu ứng khi hover */
                &_price_1:hover {
                    animation: rainbow 3s linear infinite; /* Sử dụng animation có tên là "rainbow" */
                }

                &_price_2 {
                    font-size: 40px;
                    font-weight: bold;
                    color: #ffff;
                    text-align: center;
                    background-image: linear-gradient(to right, rgba(248, 6, 228, 0.8), rgba(247, 134, 6, 0.911)); 
                }

                &_price_2:hover {
                    animation: rainbow 3s linear infinite; /* Sử dụng animation có tên là "rainbow" */
                }

                &_price_3 {
                    font-size: 40px;
                    font-weight: bold;
                    color: #ffff;
                    text-align: center;
                    background-image: linear-gradient(to right, rgba(248, 123, 6, 0.8), rgba(6, 110, 247, 0.911)); 
                }

                &_price_3:hover {
                    animation: rainbow 3s linear infinite; /* Sử dụng animation có tên là "rainbow" */
                }

                &_description {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #414040;

                    &_icon {
                        color: green;
                        margin-right: 5px;
                    }
                }

                &_btn {
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    padding: 20px;
                    width: calc(100% - 40px);
                    font-size: 20px;
                    text-align: center;
                    font-weight: bold;
                    color: #ffff;
                    text-transform: uppercase;

                    div {
                        cursor: pointer;
                        padding: 10px 25px;
                        border-radius: 5px;
                        background-color: #8651EF;
                        box-shadow: 0 2px 4px rgba(0, 0, 20, 0.08), 0 1px 2px rgba(0, 0, 20, 0.08);

                        &:hover {
                            background-color: #972efa;
                        }
                    }
                }
            }
        }
    }

}