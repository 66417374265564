.PrivacyTerms {
    width: 100%;
    display: flex;
    justify-content: center;
    &_container {
        max-width: 1220px;
        margin-left: auto;
        margin-right: auto;
        padding: 20px 0px;
    }
    &_logo {
        display: flex;
        justify-content: center;
        img {
            width: 200px;
        }
    }
    &_title {
        text-transform: uppercase;
        font-size: 25px;
        text-align: center;
        color: rgba(180, 4, 101, 0.959);
        font-weight: 500;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }

    &_content {
        padding: 10px 20px;
        border-bottom: 1px solid #ccc;
        &_title {
            text-transform: uppercase;
            font-size: 18px;
            font-weight: bold;
            
            &_big {
                font-size: 25px;
                font-weight: bold;
                color: rgba(180, 4, 101, 0.959);
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            &_small {
                font-size: 16px;
                font-weight: bold;
            }
        }

        &_policy {
            padding: 10px 20px;
        }

        &_text {
            margin: 10px 0px;
        }
    }
}