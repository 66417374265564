.Fanpage {
    width: 100%;
    &_bg {
        background-color: #4bacff;
        padding-bottom: 9.5rem;
        padding-top: 4rem;
    }
    &_upgrade {
        cursor: pointer;
        background: #FFA500;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        width: 135px;
        height: 40px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            background: #f8ad21;
        }
    }

    &_content {
        padding-right: 2rem;
        padding-left: 2rem;
        margin-top: -10rem;
        h3, h4 {
            margin: 0px;
        }
        &_header {
            &_logo {
                display: flex;
                align-items: center;
                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }
            &_title {
                width: 100%;
                color: #ffff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                >h3 {
                    font-size: calc(1.275rem + .3vw);
                }
            }
        }
    }

    &_body {
        margin-top: 2rem;
        &_content {
            width: 100%;
            padding: 10px;
            box-shadow: 0 2px 4px rgba(0,0,20,.08), 0 1px 2px rgba(0,0,20,.08);
            border: 0;
            border-radius: 0.5rem;
            background-color: #fff;
            &_title {
                display: flex;
                align-items: center;
                font-size: 18px;
                font-weight: bold;
                border-bottom: 2px solid #ccc;
                &_text {
                    cursor: pointer;
                    padding: 10px;
                    &.active {
                        border-radius: 5px 5px 0px 0px;
                        border-top: 1px solid #ccc;
                        border-right: 1px solid #ccc;
                        border-left: 1px solid #ccc;
                        border-bottom: none;
                        position: relative;
                        top: 2px;
                        background: #fff;
                    }
                }
            }
        }
        
    }
}