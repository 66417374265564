.Login {
  width: 100%;
  margin: 0 auto;
  &_container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../../../public/image/bg-02.jpg');
  }
  &_box {
    width: 350px;
    background: #fff;
    border-radius: 10px;
    position: relative;
    padding: 20px 90px;
    &_title {
      width: 100%;
      color: #555;
      font-size: 18px;
      font-weight: 700;
      padding-top: 16px;
      padding-bottom: 10px;
    }
    &_fogot {
      width: 100%;
      font-size: 14px;
      color: #3b5998;
      font-style: italic;
      margin-bottom: 20px;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    &_input {
      width: 100%;
      background-color: #f7f7f7;
      border: 1px solid #e6e6e6;
      border-radius: 10px;
      height: 58px;
      input {
        color: #333;
        line-height: 1.2;
        font-size: 18px;
        display: block;
        width: calc(100% - 40px);
        background: 0 0;
        height: 58px;
        padding: 0px 20px;
        outline: none;
        border: none;
      }
      input:focus {
        border: 1px solid #5586f0;
        border-radius: 10px;
      }
    }
  }
  &_form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &_title {
      width: 100%;
      display: block;
      font-size: 25px;
      color: #555;
      line-height: 1.2;
      text-align: center;
      padding-bottom: 15px;
      font-weight: bold;
    }
    &_logo {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  &_or {
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &_btn {
    &_login {
      background: #3b5998;
      color: white;
      display: flex;
      justify-items: center;
      align-items: center;
      width: 100%;
      padding: 15px;
      border-radius: 12px;
      font-size: 20px;
      font-weight: 700;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background-color: #5586f0;
      }
    }
    &_icon {
      font-size: 30px !important;
      margin-right: 10px;
    }
  }
  &_language {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    margin-top: 40px;
    &_title {
      margin-right: 10px;
      display: flex;
    }
    &_icon {
      color: #3b5998;
    }
    &_list {
      display: inline-block;
    }
    &_select {
      width: 135px;
      height: 40px;
    }
  }
  &_footer {
    text-align: center;
    margin-top: 10px;
    &_item {
      a {
        color: #3b5998;
        font-style: italic;
        text-decoration: none;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      
    }
  }
  &_changePage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999999;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    &_box {
        width: 70%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        &_title {
            font-size: 20px;
            color: #4bacff;
            font-weight: bold;
        }

        &_action {
            margin-top: 10px;
            display: flex;
            gap: 5px;

            &_btn1{
                padding: 10px;
                background: green;
                color: white;
                border-radius: 5px;
                cursor: pointer;
                text-decoration: none;

                &:hover {
                    background: rgb(1, 167, 1);
                }
            }

            &_btn2{
                padding: 10px;
                background: orange;
                color: white;
                border-radius: 5px;
                cursor: pointer;
                text-decoration: none;

                &:hover {
                    background: rgb(247, 166, 15);
                }
            }
        }
    }
  }
}