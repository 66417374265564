.CustomerFanpage {
    padding: 20px 0px;
    &_note {
        display: flex;
        align-items: center;
        &_title {
            font-size: 18px;
            font-style: italic;
            margin-right: 10px;
        }
        &_btn {
            cursor: pointer;
            width: max-content;
            font-size: 20px;
            font-weight: bold;
            padding: 10px 20px;
            border-radius: 10px;
            background: #80c433;
            color: white;

            &:hover {
                background: #9eeb47;
            }
        }
    }
    &_list {
        &_card {
            flex: 0 0 auto;
            width: 100%;
            &_body {
                border: 0;
                border-radius: 0.5rem;
                &_header {
                    border-radius: 0.5rem 0.5rem 0;
                    padding: 1.25rem 0 0 0;
                    background-color: #fff;
                    &_title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 1.25rem 0.75rem 1.25rem;
                        margin: 0;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.2;
                        color: #212b36;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.18);
                    }
                }
                &_table {
                    overflow: auto;
                    height: 460px;
                    &::-webkit-scrollbar {
                        width: 2px;
                        height: 2px;
                    }
                    &::-webkit-scrollbar-track {
                        background: #f1f1f1; 
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #888; 
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background: #555; 
                    }
                    tbody,td,tfoot,th,thead,tr {
                        border: 0 solid;
                        border-color: inherit;
                    }
                    &_content {
                        width: 100%;
                        min-width: 880px;
                        color: #637381;
                        vertical-align: top;
                        border-color: #dfe3e8;
                        border-collapse: collapse;

                        input[type=checkbox] {
                            position: relative;
                            bottom: 2px;
                            cursor: pointer;
                        }
                        input[type=checkbox]:before {
                            content: "";
                            display: block;
                            position: absolute;
                            width: 14px;
                            height: 14px;
                            top: 0;
                            left: 0;
                            border: 2px solid #555555;
                            border-radius: 3px;
                            background-color: #7ab2fa;
                        }
                        input[type=checkbox]:checked:after {
                            content: "";
                            display: block;
                            width: 5px;
                            height: 10px;
                            border: solid black;
                            border-width: 0 2px 2px 0;
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                            position: absolute;
                            top: 1px;
                            left: 5px;
                        }

                        >:not(caption)>*>* {
                            padding: 0.25rem 0.5rem;
                            background-color: transparent;
                            border-bottom-width: 1px;
                            box-shadow: inset 0 0 0 9999px transparent;
                        }
                        &_thead {
                            text-align: left;
                            vertical-align: bottom;
                            color: #000;
                            border-color:#dfe3e8;
                            border: 0 solid;
                        }
                        &_tr {
                            background: #fff;
                            height: 40px;
                            &_tdName {
                                display: flex;
                                align-items: center;
                                img {
                                    border-radius: 50%;
                                    width: 20px;
                                    margin-right: 5px;
                                }
                            }
                            &_Tags {
                                height: 35px;
                                width: max-content;
                                overflow-y: auto;
                            }
                        }
                        thead th {
                            font-weight: 500;
                            padding: 0.25rem 0.5rem;
                            text-transform: capitalize;
                            font-size: .875rem;
                            color: #637381;
                            text-align: left;
                        }
                    }
                }
                &_footer {
                    background-color: #fff;
                    border-radius: 0 0 0.5rem 0.5rem;
                    text-align: center;
                    padding: 1rem;
            
                    button {
                        margin: 0px 5px;
                        background: rgb(25, 117, 25);
                        border-width: 1px;
                        border-radius: 5px;
            
                        &:hover {
                            background: rgb(5, 87, 5);
                        }
                    }
                }
            }
        }
    }
}