.Campaigns {
    padding: 20px 0px;
    &_note {
        display: flex;
        align-items: center;
        &_title {
            font-size: 18px;
            font-style: italic;
            margin-right: 10px;
        }
        &_btn {
            cursor: pointer;
            width: max-content;
            font-size: 20px;
            font-weight: bold;
            padding: 10px 20px;
            border-radius: 10px;
            background: #80c433;
            color: white;

            &:hover {
                background: #9eeb47;
            }
        }
    }

    &_body {
        display: flex;
        &_left {
            width: 50%;
            &_title {
                font-weight: bold;
                font-size: 16px;
                margin: 5px 0px;
            }

            &_list {
                height: 350px;
                overflow-y: auto;
                margin-top: 10px;
                &_item {
                    cursor: pointer;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 10px;
                    border: 1px solid #7ab2fa;
                    font-size: 14px;
                    margin-bottom: 5px;
                    font-weight: bold;
                    margin-right: 10px;

                    &:hover {
                        background-color: #d3e9ff;
                    }
                }
                .active-file {
                    background-color: #d3e9ff;
                }
            }
        }

        &_right {
            min-width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            &_name {
                font-weight: bold;
                font-size: 16px;
            }
            &_file {
                font-weight: bold;
                font-size: 16px;

                &_box {
                    margin-left: 10px;
                    padding: 10px;
                    border-radius: 5px;
                    background: #d3e9ff;
                }
            }
            &_iphone {
                width: 255px;
                height: 455px;
                &_content {
                    display: flex;
                    flex-direction: column;
                    padding: 0px 20px;
                    margin-left: 12px;
                    margin-top: 40px;
                    margin-right: 35px;


                    &::-webkit-scrollbar {
                        width: 4px;
                        height: 4px;
                    }
                    &::-webkit-scrollbar-track {
                        background: #f1f1f1; 
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #888; 
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background: #555; 
                    }

                    &_text {
                        padding: 2px 8px;
                        border-radius: 5px;
                        background-color: #5252f9;
                        color: #fff;
                        white-space: pre-line;
                    }

                    &_img {
                        img {
                            border-radius: 5px;
                        }
                    }

                    &_video {
                        video {
                            border-radius: 5px;
                        }
                    }

                    &_avatar {
                        width: 20px;
                        height: 20px;
                        img {
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                        }
                    }
                }
            }
            i {
                font-size: 16px;
            }
        }
    }

}